<div class="opening-hours-creator">
  <h1 translate>openingHours.createNewOpeningHours</h1>

  <form [formGroup]="openingHourForm" (ngSubmit)="onSubmit()" class="mt-6">
    <p translate>openingHours.p1</p>
    <div class="flex flex-row flex-wrap gap-4">
      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate>monday</span>
        <input
          type="checkbox"
          formControlName="monday"
          value="monday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate
          >tuesday</span
        >
        <input
          type="checkbox"
          formControlName="tuesday"
          value="tuesday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate
          >wednesday</span
        >
        <input
          type="checkbox"
          formControlName="wednesday"
          value="wednesday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate
          >thursday</span
        >
        <input
          type="checkbox"
          formControlName="thursday"
          value="thursday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate>friday</span>
        <input
          type="checkbox"
          formControlName="friday"
          value="friday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate
          >saturday</span
        >
        <input
          type="checkbox"
          formControlName="saturday"
          value="saturday"
          class="checkbox checkbox-primary"
        />
      </label>

      <label
        class="label justify-start flex flex-row gap-2"
        style="min-width: 100px"
      >
        <span class="label-text" style="min-width: 70px" translate>sunday</span>
        <input
          type="checkbox"
          formControlName="sunday"
          value="sunday"
          class="checkbox checkbox-primary"
        />
      </label>
    </div>

    <div class="flex flex-col mt-6">
      <p translate>openingHours.ifYouHave</p>
      <app-translateable-input
        formControlName="name"
        [title]="'general.name' | translate"
      ></app-translateable-input>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text" translate>openingHours.opens</span>
        </label>
        <input
          type="time"
          class="input input-bordered"
          formControlName="opens"
        />
      </div>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text" translate>openingHours.closes</span>
        </label>
        <input
          type="time"
          class="input input-bordered"
          formControlName="closes"
        />
      </div>

      <div class="form-control mt-5 hidden sm:block">
        <label class="label">
          <span class="label-text" translate
            >openingHours.cutoffInMinutesLabel</span
          >
        </label>
        <input
          type="text"
          class="input input-bordered"
          formControlName="bookingClosesMinutesBefore"
          required
        />
        <label class="label">
          <span class="label-text" translate
            >openingHours.cuttoffInMinutesTooltip</span
          >
        </label>
        <div
          class="form-control-error"
          *ngIf="
            openingHourForm.controls['bookingClosesMinutesBefore'].invalid &&
            openingHourForm.controls['bookingClosesMinutesBefore'].touched
          "
        >
          <span translate>openingHours.cutoffInMinutesRequired</span>
        </div>
      </div>

      <div
        class="text-blue-500 cursor-pointer mt-4"
        (click)="toggleAdvancedSettings()"
        *ngIf="!showAdvancedSettings"
        translate
      >
        general.showadvanced
      </div>
      <div
        class="text-blue-500 cursor-pointer mt-4"
        (click)="toggleAdvancedSettings()"
        *ngIf="showAdvancedSettings"
        translate
      >
        general.hideadvanced
      </div>
      <div class="" *ngIf="showAdvancedSettings">
        <div class="form-control mt-5">
          <label class="label">
            <span class="label-text" translate
              >openinghours.activeFromDate</span
            >
          </label>
          <input
            type="date"
            class="input input-bordered"
            formControlName="activeFromDate"
            required
          />
        </div>

        <div class="form-control mt-5">
          <label class="label">
            <span class="label-text" translate>openinghours.activeToDate</span>
          </label>
          <input
            type="date"
            class="input input-bordered"
            formControlName="activeToDate"
          />
        </div>

        <div class="form-control mt-5">
          <label class="label justify-start gap-3">
            <span class="label-text" translate>openingHours.isOpen</span>
            <input
              type="checkbox"
              class="checkbox checkbox-primary"
              formControlName="isOpen"
            />
          </label>
          <label translate>openingHours.isOpenDesc</label>
        </div>

        <div class="form-control mt-5">
          <label class="label justify-start gap-3">
            <span class="label-text" translate
              >openingHours.specialOpeningHoursThatShouldOverrideOthers</span
            >
            <input
              type="checkbox"
              class="checkbox checkbox-primary"
              formControlName="specialOpeningHoursThatShouldOverrideOthers"
            />
          </label>
          <label translate
            >openingHours.specialOpeningHoursThatShouldOverrideOthersDesc</label
          >
        </div>
      </div>
    </div>

    <div class="error" *ngIf="noDaysHaveBeenChecked" translate>
      openingHours.atLeastOneDayHasBeenChecked
    </div>
    <div class="flex flex-row justify-center gap-4 mt-12 mb-6">
      <button class="btn btn-secondary btn-outline" (click)="close()" translate>
        general.close
      </button>
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="isLoading"
        translate
      >
        {{ !isLoading ? ("general.save" | translate) : "" }}
        <app-loader
          [isLoading]="isLoading"
          *ngIf="isLoading"
          class="inline-loader"
        ></app-loader>
      </button>
    </div>
  </form>
</div>
