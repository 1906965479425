import { DatePipe } from '@angular/common';
import { OnInit, Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@app/i18n';
import { DateTime } from 'luxon';
import { SupabaseService } from '@app/shared/supabase.service';
@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class Datei18nPipe implements PipeTransform, OnInit {
  constructor(
    private i18: I18nService,
    private supabaseService: SupabaseService
  ) {}

  async ngOnInit(): Promise<void> {}

  public transform(
    value: Date | DateTime | null | undefined,
    datePattern: 'full' | 'long' | 'medium' | 'short' | undefined,
    timePattern?: 'full' | 'long' | 'medium' | 'short' | undefined,
    force12HourFormat?: boolean | undefined
  ): any {
    if (!value) {
      return '';
    }

    let datetime: DateTime;
    if (value instanceof Date) {
      datetime = DateTime.fromJSDate(value);
    } else if (typeof value === 'string') {
      datetime = DateTime.fromISO(value);
    } else if (DateTime.isDateTime(value)) {
      datetime = value;
    } else {
      return '';
    }

    if (datePattern === 'short' && typeof timePattern === 'undefined') {
      return datetime.toFormat(this.supabaseService.DATE_FORMAT_FOR_TENANT);
    } else if (datePattern === 'short' && typeof timePattern !== 'undefined') {
      if (this.supabaseService.HOUR_12_TIME_FORMAT) {
        return datetime.toFormat(
          this.supabaseService.DATE_FORMAT_FOR_TENANT + ' hh:mm a'
        );
      } else {
        return datetime.toFormat(
          this.supabaseService.DATE_FORMAT_FOR_TENANT + ' HH:mm'
        );
      }
    } else {
      let opts = { locale: this.i18.currentLanguage };
      return datetime.toLocaleString(
        {
          dateStyle: datePattern,
          timeStyle: timePattern,
          hour12: this.supabaseService.HOUR_12_TIME_FORMAT,
        },
        opts
      );
    }
  }
}
