import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime, Interval } from 'luxon';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';
import { TranslationService } from '@app/shared/translation.service';
import { OpeninghoursService } from '@app/shared/openinghours.service';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { SupabaseService } from '@app/shared/supabase.service';

/**
 * Restraurant is needed to know what restaurant the opening hours is for.
 * AreaID is optional. If none is set the opening hours is for the entire restaurant.
 * If areaID is set the opening hours is for a specific area.
 * Pass in an existing OpeningHours object to edit it, undefiend if you want to create a new one.
 */
interface OpeningHoursDialogData {
  restaurantId: number;
  areaId?: number;
}

@Component({
  selector: 'app-opening-hours-creator-dialog',
  templateUrl: './opening-hours-creator-dialog.component.html',
  styleUrls: ['./opening-hours-creator-dialog.component.scss'],
})
export class OpeningHoursCreatorDialogComponent {
  openingHourForm = new FormGroup({
    monday: new FormControl(false),
    tuesday: new FormControl(false),
    wednesday: new FormControl(false),
    thursday: new FormControl(false),
    friday: new FormControl(false),
    saturday: new FormControl(false),
    sunday: new FormControl(false),
    opens: new FormControl('12:00'),
    closes: new FormControl('23:00'),
    bookingClosesMinutesBefore: new FormControl(0),
    name: new FormControl<ITranslationVM[]>([
      {
        title: this.translateService.instant('openingHours.name.title'),
        description: this.translateService.instant(
          'openingHours.name.description'
        ),
      },
    ]),
    activeFromDate: new FormControl<string>(
      DateTime.now().toFormat('yyyy-MM-dd'),
      { nonNullable: true }
    ),
    activeToDate: new FormControl<string>(
      DateTime.now().plus({ year: 3 }).toFormat('yyyy-MM-dd'),
      { nonNullable: true }
    ),
    specialOpeningHoursThatShouldOverrideOthers: new FormControl<boolean>(
      false,
      { nonNullable: true }
    ),
    isOpen: new FormControl<boolean>(true, { nonNullable: true }),
  });
  private restaurantId: number = -1;
  private areaId?: number;
  noDaysHaveBeenChecked: boolean = false;

  showAdvancedSettings: boolean = false; // Initialize with false

  isLoading = false;

  constructor(
    private translationService: TranslationService,
    private openingHourService: OpeninghoursService,
    public dialogRef: DialogRef<OpeningHoursDialogData>,
    @Inject(DIALOG_DATA) public data: OpeningHoursDialogData,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private supabaseService: SupabaseService
  ) {
    this.restaurantId = data.restaurantId;
    this.areaId = data.areaId; // if undefined then we are working on general opening hours for the restaurant.
  }

  async onSubmit() {
    let values = this.openingHourForm.getRawValue();

    if (this.openingHourForm.valid) {
      this.isLoading = true;
      const days = [
        values.monday,
        values.tuesday,
        values.wednesday,
        values.thursday,
        values.friday,
        values.saturday,
        values.sunday,
      ];

      this.noDaysHaveBeenChecked = days.filter((d) => d).length === 0;
      if (this.noDaysHaveBeenChecked) {
        return;
      }

      if (values.opens === null || values.closes === null) {
        throw new Error('Opening or closing hours should never be null');
      }

      if (values.bookingClosesMinutesBefore === null) {
        throw new Error('bookingClosesMinutesBefore should never be null');
      }

      let tenant = await this.supabaseService.getTenant();
      const openingDateTime = DateTime.fromFormat(
        values.opens,
        tenant.hourFormatForDateTime
      ).set({
        second: 0,
        millisecond: 0,
      });
      const closesDateTime = DateTime.fromFormat(
        values.closes,
        tenant.hourFormatForDateTime
      ).set({
        second: 0,
        millisecond: 0,
      });
      const midnightDateTime = DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const openingHoursAsMinutesFromMidnight = Interval.fromDateTimes(
        midnightDateTime,
        openingDateTime
      ).length('minutes');
      const closingHoursAsMinutesFromMidnight = Interval.fromDateTimes(
        midnightDateTime,
        closesDateTime
      ).length('minutes');

      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const index = i;
        if (!day) {
          continue;
        }

        const overlappingOpeningHours =
          await this.openingHourService.checkIfOpeningHoursOverlap(
            index + 1,
            DateTime.fromISO(values.activeFromDate + ''),
            DateTime.fromISO(values.activeToDate + ''),
            values.specialOpeningHoursThatShouldOverrideOthers,
            values.isOpen,
            this.areaId === 0 ? null : this.areaId,
            this.restaurantId,
            null,
            openingHoursAsMinutesFromMidnight,
            closingHoursAsMinutesFromMidnight
          );

        if (overlappingOpeningHours.length > 0) {
          const overlappingHoursMessages = overlappingOpeningHours
            .map(
              (oh) =>
                `${
                  oh.name
                    ? oh.name
                    : DateTime.now()
                        .set({ weekday: oh.weekday })
                        .toFormat('cccc', {
                          locale: this.translateService.currentLang,
                        })
                } - ${oh.activeFromDate.toFormat(
                  'dd.MM.yyyy'
                )} - ${oh.activeToDate.toFormat('dd.MM.yyyy')}`
            )
            .join('\n');

          const message = this.translateService.instant(
            'openingHours.overlapping.error'
          );

          this.toastrService.error(overlappingHoursMessages, message);
          this.isLoading = false;
          return;
        }
      }

      const textIdToStoreInDB: null | number =
        await this.translationService.storeTranslationsAndGetTextIdToStoreInDb(
          values.name as ITranslationVM[]
        );

      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const index = i;
        if (!day) {
          continue;
        }

        console.log('this.restaurantId ', +this.restaurantId);
        console.log('Weekday ', +(index + 1));
        console.log(
          'openingHoursAsMinutesFromMidnight ',
          +openingHoursAsMinutesFromMidnight
        );
        console.log(
          'bookingClosesMinutesBefore ',
          +values.bookingClosesMinutesBefore
        );

        this.openingHourService
          .upsertOpeningHours(
            null, //create a new id
            this.restaurantId,
            index + 1, // day number
            openingHoursAsMinutesFromMidnight,
            closingHoursAsMinutesFromMidnight,
            values.bookingClosesMinutesBefore,
            values.activeFromDate + '',
            values.activeToDate + '',
            values.specialOpeningHoursThatShouldOverrideOthers,
            values.isOpen,
            this.areaId,
            textIdToStoreInDB
          )
          .then((response) => {
            this.dialogRef.close(response);
            this.isLoading = false;
          });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  toggleAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }
}
