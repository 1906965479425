import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { Location } from '@angular/common';

import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';
import { TranslationService } from '@app/shared/translation.service';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-number-of-areas',
  templateUrl: './onboarding-name-of-areas.component.html',
  styleUrls: ['./onboarding-name-of-areas.component.scss'],
})
export class OnboardingNameOfAreasComponent implements OnInit {
  restaurantId: number = -1;

  form = this.formBuilder.group({
    areas: this.formBuilder.array([]),
  });
  numberOfAreas!: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: Dialog,
    private formBuilder: FormBuilder,
    private supabase: SupabaseService,
    private location: Location,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    const paramRestaurantId = this.route.snapshot.paramMap.get('restaurant-id');
    const paramNumberOfAreas =
      this.route.snapshot.paramMap.get('number-of-areas');
    this.restaurantId = paramRestaurantId ? +paramRestaurantId : -1;

    let areas = await this.supabase.getAreas(this.restaurantId);
    const skipThisStepAsTheUserHasAlreadyCreatedAreas = areas.length > 0;
    if (skipThisStepAsTheUserHasAlreadyCreatedAreas) {
      this.router.navigate([onboardingRouteSteps.step43, this.restaurantId]);
    }

    if (paramNumberOfAreas === null) {
      throw new Error('Number of areas is not set, this must always be set..');
    }
    this.numberOfAreas = +paramNumberOfAreas;

    let iTenant = await this.supabase.getTenant();

    const nameText = this.translateService.instant(
      'nameOfAreas.onboarding.nameOfAreas'
    );
    const descriptionText = this.translateService.instant(
      'nameOfAreas.onboarding.nameDesc'
    );

    for (let i = 0; i < this.numberOfAreas; i++) {
      const area = this.formBuilder.group({
        name: new FormControl<ITranslationVM[]>(
          // Dette er slik man lager et translatable input som ikke har data fra før
          [
            {
              title: nameText,
              description: descriptionText,
              language: iTenant.defaultLanguage,
            },
          ]
        ),
        bookingPriority: [50, Validators.required],
        availableOnline: [true],
      });

      this.areas.push(area);
    }
  }

  get areas(): FormArray {
    return this.form.controls['areas'] as FormArray;
  }

  // hack fra https://stackoverflow.com/questions/67000674/type-abstractcontrol-is-not-assignable-to-type-formgroup
  getFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }
  shouldShowRequiredMessage(control: AbstractControl, prop: string): boolean {
    const ctrl = control.get(prop) as FormControl;
    return ctrl.invalid && ctrl.touched;
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step41, this.restaurantId]);
  }

  async next() {
    if (this.form.valid) {
      for (let area of this.form.value.areas || []) {
        if (!area) {
          return;
        }

        // @ts-ignore
        const translationName = area.name as ITranslationVM[];

        const textIdToStoreInDB: null | number =
          await this.translationService.storeTranslationsAndGetTextIdToStoreInDb(
            translationName
          );

        if (textIdToStoreInDB === null) {
          continue;
        }

        await this.supabase.createArea(
          this.restaurantId,
          undefined,
          textIdToStoreInDB,
          // @ts-ignore
          area.bookingPriority,
          // @ts-ignore
          area.availableOnline
        );
      }

      await this.supabase.updateUsersProgressInOnboardingProcess(
        'nameOfMultipleAreasDone'
      );

      this.router.navigate([onboardingRouteSteps.step43, this.restaurantId]);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
