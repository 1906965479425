<h1 class="text-2xl font-bold mb-4" translate>areaCreator.newArea</h1>
<div class="mb-4">
  <p translate>areaCreator.description</p>
</div>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)">
  <div class="inputs mb-4">
    <div class="form-control mb-4">
      <app-translateable-input
        formControlName="name"
        [title]="'nameOfAreas.onboarding.name' | translate"
      ></app-translateable-input>
    </div>

    <div class="form-control mb-4">
      <label class="label">
        <span class="label-text" translate>areaCreator.bookingPriority</span>
      </label>
      <div class="flex flex-row gap-3">
        <input
          type="range"
          min="0"
          max="100"
          value="50"
          class="range w-96"
          formControlName="bookingPriority"
        />
        <div class="">{{ form.controls.bookingPriority.value }}</div>
        <div *ngIf="form.controls.bookingPriority.value >= 90" translate>
          areaCreator.highest
        </div>
        <div
          *ngIf="
            form.controls.bookingPriority.value >= 70 &&
            form.controls.bookingPriority.value < 90
          "
          translate
        >
          areaCreator.high
        </div>
        <div
          *ngIf="
            form.controls.bookingPriority.value >= 50 &&
            form.controls.bookingPriority.value < 70
          "
          translate
        >
          Medium priority
        </div>
        <div
          *ngIf="
            form.controls.bookingPriority.value >= 20 &&
            form.controls.bookingPriority.value < 50
          "
          translate
        >
          areaCreator.low
        </div>
        <div *ngIf="form.controls.bookingPriority.value < 20" translate>
          areaCreator.lowest
        </div>
      </div>
    </div>

    <div class="form-control mb-4">
      <label class="label cursor-pointer justify-start gap-3">
        <span class="label-text" translate>areaCreator.onlineBooking</span>
        <input
          type="checkbox"
          formControlName="availableOnline"
          class="checkbox checkbox-primary"
        />
      </label>
    </div>
  </div>
  <div class="actions flex justify-between">
    <button type="button" class="btn btn-outline" (click)="close()" translate>
      general.cancel
    </button>
    <button class="btn btn-primary" type="submit" translate>
      general.save
    </button>
  </div>
</form>
