import { Injectable, OnInit } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { DateTime } from 'luxon';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';
import { OpeningHoursMapper } from '@app/shared/SupabaseTypes/opening-hours-mapper';
import { TranslationService } from '@app/shared/translation.service';
@Injectable({
  providedIn: 'root',
})
export class OpeninghoursService {
  private localStorage2: {
    openingHoursByRestaurant: Map<string, IOpeningHours[]>; // restaurantId -> openingHours
  };

  constructor(
    private translationService: TranslationService,
    private supabaseService: SupabaseService
  ) {
    this.localStorage2 = {
      openingHoursByRestaurant: new Map<string, IOpeningHours[]>(),
    };
  }

  async upsertOpeningHours(
    id: number | null,
    restaurantId: number,
    weekday: number,
    openingHoursAsMinutesFromMidnight: number,
    closingHoursAsMinutesFromMidnight: number,
    bookingClosesMinutesBefore: number,
    activeFromDate: string,
    activeToDate: string,
    specialOpeningHoursThatShouldOverrideOthers: boolean,
    isOpen: boolean,
    areaId: number | null | undefined,
    textId?: number | null
  ): Promise<any> {
    const closingTimeInMinutesIsLessThanTheOpeningTimeMeansThatTheClosingTimeIsTheNextDay =
      closingHoursAsMinutesFromMidnight < openingHoursAsMinutesFromMidnight;

    if (
      closingTimeInMinutesIsLessThanTheOpeningTimeMeansThatTheClosingTimeIsTheNextDay
    ) {
      const minutesInADay = 1440;
      closingHoursAsMinutesFromMidnight += minutesInADay;
    }

    const tmpAreaId = areaId === 0 ? null : areaId;
    const result = await this.supabaseService
      .getSupabaseNativeClient()
      .from('general_opening_hours')
      .upsert({
        ...(id !== null ? { id } : {}),
        restaurantId,
        areaId: tmpAreaId,
        weekday,
        opens: openingHoursAsMinutesFromMidnight,
        closes: closingHoursAsMinutesFromMidnight,
        bookingClosesMinutesBefore,
        textId,
        activeFromDate,
        activeToDate,
        specialOpeningHoursThatShouldOverrideOthers,
        isOpen,
      })
      .select()
      .single();

    this.localStorage2.openingHoursByRestaurant.clear();

    if (result.data) {
      return new OpeningHoursMapper().toDomain(
        result.data,
        (await this.supabaseService.getTenant()).hourFormatForDateTime
      );
    } else {
      throw new Error('Could not upsert opening hours');
    }
  }

  async getOpeningHoursByRestaurant(
    restaurantId?: number // denne er optional slik at jeg kan kalle denne metoden fra overview sin loadInitialData(). Det gjør at den initielle lastingen av applikasjonen er mye kjappere
  ): Promise<IOpeningHours[]> {
    let storedOpeningHours = this.localStorage2.openingHoursByRestaurant.get(
      restaurantId + ''
    );
    if (storedOpeningHours) {
      return storedOpeningHours;
    }

    console.log(
      'this.localStorage2.openingHoursByRestaurant',
      this.localStorage2.openingHoursByRestaurant
    );

    const query = this.supabaseService
      .getSupabaseNativeClient()
      .from('general_opening_hours')
      .select();
    if (restaurantId) {
      query.eq('restaurantId', restaurantId);
    }
    return query.then(async (res) => {
      if (res.data) {
        const textIdList: number[] = [];
        const openingHoursArray: IOpeningHours[] = [];
        for (const dbOpeningHour of res.data) {
          openingHoursArray.push(
            new OpeningHoursMapper().toDomain(
              dbOpeningHour,
              (await this.supabaseService.getTenant()).hourFormatForDateTime
            )
          );

          if (
            dbOpeningHour.textId &&
            !textIdList.includes(dbOpeningHour.textId)
          ) {
            // Avoid duplicate calls to backend
            textIdList.push(dbOpeningHour.textId);
          }
        }

        const textTranslationsMap = new Map<number, string>();

        // Fetch translations and populate the map
        return Promise.all(
          textIdList.map(async (textId) => {
            return this.translationService
              .getTextInDefaultLanguage(textId)
              .then((translation) => {
                textTranslationsMap.set(textId, translation);
              });
          })
        ).then(() => {
          // Assign translations to the corresponding openingHours
          openingHoursArray.forEach((openingHours) => {
            if (openingHours.textId) {
              openingHours.name =
                textTranslationsMap.get(openingHours.textId) || '';
            }
          });

          // Group by restaurantId
          const groupedByRestaurantId = openingHoursArray.reduce(
            (acc, curr) => {
              if (!acc[curr.restaurantId]) {
                acc[curr.restaurantId] = [];
              }
              acc[curr.restaurantId].push(curr);
              return acc;
            },
            {} as Record<number, IOpeningHours[]>
          );

          // Sort and set in localStorage2
          Object.keys(groupedByRestaurantId).forEach((restaurantId) => {
            const sortedOpeningHours = groupedByRestaurantId[restaurantId].sort(
              (a: any, b: any) => a.weekday - b.weekday
            );
            this.localStorage2.openingHoursByRestaurant.set(
              restaurantId + '',
              sortedOpeningHours
            );
          });

          return (
            this.localStorage2.openingHoursByRestaurant.get(
              restaurantId + ''
            ) || []
          );
        });
      } else {
        return [];
      }
    });
  }

  private openingHoursCache = new Map<string, Promise<IOpeningHours[]>>();

  getOpeningHoursByDate(
    dateTime: DateTime,
    selectedRestaurant: number
  ): Promise<IOpeningHours[]> {
    const cacheKey = `${dateTime.toISO()}_${selectedRestaurant}`;

    if (this.openingHoursCache.has(cacheKey)) {
      return this.openingHoursCache.get(cacheKey)!;
    }

    const tenantPromise = this.supabaseService.getTenant();
    const hoursPromise = this.getOpeningHoursByRestaurant(selectedRestaurant);

    const promise = Promise.all([tenantPromise, hoursPromise]).then((x) => {
      const tenantData = x[0];
      const allOpeningHoursForRestaurant = x[1];
      let openingHourOnThisDate = allOpeningHoursForRestaurant.filter((o) => {
        const weekDayMatch = o.weekday === dateTime.get('weekday');
        const restaurantMatch = o.restaurantId === selectedRestaurant;
        const activeDateFromMatch = dateTime >= o.activeFromDate;
        const activeDateToMatch = dateTime <= o.activeToDate;

        return (
          weekDayMatch &&
          restaurantMatch &&
          activeDateFromMatch &&
          activeDateToMatch
        );
      });

      const hasOverridingSpecialOpeningHours = openingHourOnThisDate.some(
        (o) => o.specialOpeningHoursThatShouldOverrideOthers
      );
      if (hasOverridingSpecialOpeningHours) {
        openingHourOnThisDate = openingHourOnThisDate.filter(
          (o) => o.specialOpeningHoursThatShouldOverrideOthers
        );
      }

      console.log(
        'overview openingHourOnThisDate',
        dateTime.toFormat('yyyy-MM-dd HH:mm')
      );
      openingHourOnThisDate = openingHourOnThisDate.map((tmp) => {
        tmp.opensDateTime = dateTime
          .set({ hour: 0, minute: 0 })
          .plus({ minute: tmp.opens });

        tmp.opensString = tmp.opensDateTime.toFormat(
          tenantData.hourFormatForDateTime
        );

        tmp.closesDateTime = dateTime
          .set({ hour: 0, minute: 0 })
          .plus({ minute: tmp.closes });

        const closesAfterMidnight = tmp.closesDateTime < tmp.opensDateTime;
        if (closesAfterMidnight) {
          tmp.closesDateTime = tmp.closesDateTime.plus({ day: 1 });
        }

        tmp.closesString = tmp.closesDateTime.toFormat(
          tenantData.hourFormatForDateTime
        );

        return tmp;
      });
      return openingHourOnThisDate;
    });

    this.openingHoursCache.set(cacheKey, promise);
    return promise;
  }

  async deleteGeneralOpeningHour(openingHourId: number): Promise<any> {
    const result = await this.supabaseService
      .getSupabaseNativeClient()
      .from('general_opening_hours')
      .delete()
      .eq('id', openingHourId)
      .select();

    this.localStorage2.openingHoursByRestaurant.clear();

    if (result.data) {
      const idsToDelete: number[] = result.data
        .map((openingHours: any) => openingHours.textId)
        .filter((id: number | null) => id !== null);
      await this.supabaseService
        .getSupabaseNativeClient()
        .from('translations')
        .delete()
        .in('textId', idsToDelete);
    }

    return result;
  }

  async checkIfOpeningHoursOverlap(
    weekday: number,
    activeFromDate: DateTime,
    activeToDate: DateTime,
    specialOpeningHoursThatShouldOverrideOthers: boolean,
    isOpen: boolean,
    areaId: number | undefined | null,
    restaurantId: number,
    openingHoursId: number | null,
    opensMinutesFromMidnight: number,
    closesMinutesFromMidnight: number
  ): Promise<IOpeningHours[]> {
    let res = await this.getOpeningHoursByRestaurant(restaurantId);

    // hvis man lager en "stengetid" så gjør det ikke noe om de overlapper..
    if (!isOpen) {
      return [];
    }

    if (openingHoursId) {
      // fjerne seg selv hvis man redigerer en åpningstid
      res = res.filter((oh) => oh.id !== openingHoursId);
    }

    const openingHoursOnWeekday = res.filter(
      (oh) =>
        oh.weekday === weekday && oh.areaId === areaId && oh.isOpen === true // siste fordi vi ikke skal sjekke mot stengetider
    );

    const openingHoursThatAreActive = openingHoursOnWeekday.filter((oh) => {
      const activeFromDateIsBefore = oh.activeFromDate <= activeToDate;
      const activeToDateIsAfter = oh.activeToDate >= activeFromDate;

      return activeFromDateIsBefore && activeToDateIsAfter;
    });

    const openingHoursThatOverlap = openingHoursThatAreActive.filter((oh) => {
      // sjekk om opensMinutesFromMidnight er >= oh.opens

      if (
        (opensMinutesFromMidnight >= oh.opens &&
          opensMinutesFromMidnight < oh.closes) ||
        (closesMinutesFromMidnight > oh.opens &&
          closesMinutesFromMidnight <= oh.closes)
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (specialOpeningHoursThatShouldOverrideOthers) {
      return [];
    }

    console.log('Opening hours overlap', openingHoursThatOverlap);

    return openingHoursThatOverlap;
  }
}
