import { IReservationLength } from './IReservationLengths';

export class ReservationLengthMapper {
  toDomain(raw: any): IReservationLength {
    return {
      id: raw.id,
      tenantId: raw.tenantId,
      restaurantId: raw.restaurantId,
      guests: raw.guests,
      lengthInMinutes: raw.lengthInMinutes,
    };
  }
}
