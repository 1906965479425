import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-onboarding-restaurant-editor',
  templateUrl: './onboarding-restaurant-editor.component.html',
  styleUrls: ['./onboarding-restaurant-editor.component.scss'],
})
export class OnboardingRestaurantEditorComponent implements OnInit {
  restaurantForm = new FormGroup({
    name: new FormControl('', Validators.required),
    regulatoryMaxGuestCapacity: new FormControl(100),
    reservationIntervals: new FormControl(15),
  });
  isLoading: boolean = false;
  private restaurant: any;
  restaurantId = -1;

  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    const paramId = this.route.snapshot.paramMap.get('id');
    this.restaurantId = paramId ? +paramId : -1;

    this.supabase.getRestaurants().then((restaurantData: any[]) => {
      if (this.restaurantId > -1) {
        this.restaurant = restaurantData.find(
          (t) => t.id === this.restaurantId
        );
      } else {
        this.restaurant = restaurantData.length > 0 ? restaurantData[0] : null;
      }
      if (this.restaurant) {
        this.restaurantForm.get('name')?.setValue(this.restaurant.name);
        this.restaurantForm
          .get('regulatoryMaxGuestCapacity')
          ?.setValue(this.restaurant.regulatoryMaxGuestCapacity);
        this.restaurantForm
          .get('reservationIntervals')
          ?.setValue(this.restaurant.reservationIntervals);
      }
    });
  }

  async onSubmit() {
    let values = this.restaurantForm.getRawValue();
    if (
      this.restaurantForm.valid &&
      values.name &&
      values.regulatoryMaxGuestCapacity &&
      values.reservationIntervals
    ) {
      this.isLoading = true;
      const x = await this.supabase.upsertRestaurant(
        this.restaurant?.id,
        values.name,
        values.regulatoryMaxGuestCapacity,
        values.reservationIntervals
      );

      await this.supabase.updateUsersProgressInOnboardingProcess(
        'restaurantDetailsCompleted'
      );

      const restaurantWasModified = this.restaurant?.id; // ie it existed from before
      if (restaurantWasModified) {
        this.router.navigate([onboardingRouteSteps.step21, this.restaurant.id]);
      } else {
        this.router.navigate([onboardingRouteSteps.step21, x.data.id]);
      }
    }
  }

  public back(): void {
    this.router.navigate([onboardingRouteSteps.step1]);
  }
}
