import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import ISO6391 from 'iso-639-1';
import { registerLocaleData } from '@angular/common';

import { supportedLanguages } from '../supported-languages';

const languageKey = 'language';

declare var Datepicker: any;
export interface IAppLanguage {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage!: string;
  private currentLanguageSubject = new BehaviorSubject<string | null>(null);
  currentLanguage$ = this.currentLanguageSubject.asObservable();

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService) {
    this.currentLanguage = this.translateService.currentLang;
  }

  get currentLanguage(): string {
    return this.currentLanguageSubject.value || 'en';
  }

  set currentLanguage(lang: string | null) {
    this.currentLanguageSubject.next(lang);
  }

  private async loadTranslations(languageToLoad: string) {
    if (supportedLanguages.length === 0) {
      const message =
        'Ingen språk er satt opp. Kjørte du ng serve i stedenfor npm run start? Package.json prestart scriptet må kjøres for å generere supported-languages.ts.';
      alert(message);
      throw new Error(message);
    }

    if (supportedLanguages.includes(languageToLoad)) {
      const tmplang = await import(`../../translations/${languageToLoad}.json`);
      this.translateService.setTranslation(languageToLoad, tmplang);
    }
    console.debug('The following languages are supported:', supportedLanguages);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  async init(defaultLanguage: string) {
    this.defaultLanguage = defaultLanguage;

    this.initLanguage(null, false);
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  async initLanguage(language: string | null, reloadBrowser: boolean) {
    let newLanguage =
      language ||
      localStorage.getItem(languageKey) ||
      this.translateService.getBrowserCultureLang() ||
      '';
    let isSupportedLanguage = supportedLanguages.includes(newLanguage);

    // If no exact match is found, search without the region
    if (newLanguage && !isSupportedLanguage) {
      newLanguage = newLanguage.split('-')[0];
      newLanguage =
        supportedLanguages.find((supportedLanguage) =>
          supportedLanguage.startsWith(newLanguage)
        ) || '';
      isSupportedLanguage = Boolean(newLanguage);
    }

    // Fallback if language is not supported
    if (!newLanguage || !isSupportedLanguage) {
      newLanguage = this.defaultLanguage;
    }

    language = newLanguage;

    console.debug(`Language set to ${language}`);

    localStorage.setItem(languageKey, language);

    await this.loadTranslations(language);
    this.registerAngularLocale(language);
    this.initDatepickerLanguages(language);

    this.translateService.use(language);
    this.currentLanguage = language;

    if (reloadBrowser) {
      window.location.reload();
    }
  }

  supportedLanguagesObj(): IAppLanguage[] {
    return supportedLanguages
      .map((m) => {
        return {
          code: m,
          name: ISO6391.getNativeName(m.substring(0, 2)),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  languageShortCode(): string {
    return this.translateService.currentLang.substring(0, 2);
  }

  // importerer dynamisk for å slippe å gjøre dette manuelt for hvert nye språk
  private async initDatepickerLanguages(lang: string) {
    if (lang.startsWith('en')) {
      return; // skip siden engelsk er default og allerede lastet
    }

    try {
      // Try to import the locale file with the full language code
      let flowBiteLocale = lang;
      if (lang.startsWith('zh')) {
        flowBiteLocale = 'zh-CN';
      } else if (lang.startsWith('pt-PT')) {
        flowBiteLocale = 'pt';
      } else if (lang.startsWith('nb')) {
        flowBiteLocale = 'no';
      }
      const locale = await import(
        `node_modules/flowbite-datepicker/js/i18n/locales/${flowBiteLocale}.js`
      );
      Datepicker.locales[lang] = locale.default[flowBiteLocale];
    } catch (e) {
      try {
        // If the import fails, try to import the locale file without the region code
        const shortLang = lang.split('-')[0];
        const locale = await import(
          `node_modules/flowbite-datepicker/js/i18n/locales/${shortLang}.js`
        );
        Datepicker.locales[lang] = locale.default[shortLang];
      } catch (e) {
        try {
          // siste forsøk, prøv å importere med bare regionskoden
          if (lang.includes('-')) {
            const shortLang = lang.split('-')[1];
            const locale = await import(
              `node_modules/flowbite-datepicker/js/i18n/locales/${shortLang}.js`
            );
            Datepicker.locales[lang] = locale.default[shortLang];
          }
        } catch (e) {
          console.error(`Failed to load locale for language ${lang}`);
        }
      }
    }
  }

  registerAngularLocale(lang: string) {
    if (lang === 'ar') {
      const locale = import('@angular/common/locales/ar');
      registerLocaleData(locale, 'ar');
    } else if (lang === 'bg') {
      const locale = import('@angular/common/locales/bg');
      registerLocaleData(locale, 'bg');
    } else if (lang === 'cs') {
      const locale = import('@angular/common/locales/cs');
      registerLocaleData(locale, 'cs');
    } else if (lang === 'da') {
      const locale = import('@angular/common/locales/da');
      registerLocaleData(locale, 'da');
    } else if (lang === 'de') {
      const locale = import('@angular/common/locales/de');
      registerLocaleData(locale, 'de');
    } else if (lang === 'el') {
      const locale = import('@angular/common/locales/el');
      registerLocaleData(locale, 'el');
    } else if (lang === 'en') {
      const locale = import('@angular/common/locales/en');
      registerLocaleData(locale, 'en');
    } else if (lang === 'es') {
      const locale = import('@angular/common/locales/es');
      registerLocaleData(locale, 'es');
    } else if (lang === 'et') {
      const locale = import('@angular/common/locales/et');
      registerLocaleData(locale, 'et');
    } else if (lang === 'fi') {
      const locale = import('@angular/common/locales/fi');
      registerLocaleData(locale, 'fi');
    } else if (lang === 'fr') {
      const locale = import('@angular/common/locales/fr');
      registerLocaleData(locale, 'fr');
    } else if (lang === 'hr') {
      const locale = import('@angular/common/locales/hr');
      registerLocaleData(locale, 'hr');
    } else if (lang === 'hu') {
      const locale = import('@angular/common/locales/hu');
      registerLocaleData(locale, 'hu');
    } else if (lang === 'id') {
      const locale = import('@angular/common/locales/id');
      registerLocaleData(locale, 'id');
    } else if (lang === 'it') {
      const locale = import('@angular/common/locales/it');
      registerLocaleData(locale, 'it');
    } else if (lang === 'ja') {
      const locale = import('@angular/common/locales/ja');
      registerLocaleData(locale, 'ja');
    } else if (lang === 'lt') {
      const locale = import('@angular/common/locales/lt');
      registerLocaleData(locale, 'lt');
    } else if (lang === 'lv') {
      const locale = import('@angular/common/locales/lv');
      registerLocaleData(locale, 'lv');
    } else if (lang === 'nb') {
      const locale = import('@angular/common/locales/nb');
      registerLocaleData(locale, 'nb');
    } else if (lang === 'nl') {
      const locale = import('@angular/common/locales/nl');
      registerLocaleData(locale, 'nl');
    } else if (lang === 'pl') {
      const locale = import('@angular/common/locales/pl');
      registerLocaleData(locale, 'pl');
    } else if (lang === 'pt-BR') {
      const locale = import('@angular/common/locales/pt');
      registerLocaleData(locale, 'pt-BR');
    } else if (lang === 'pt-PT') {
      const locale = import('@angular/common/locales/pt');
      registerLocaleData(locale, 'pt-PT');
    } else if (lang === 'ro') {
      const locale = import('@angular/common/locales/ro');
      registerLocaleData(locale, 'ro');
    } else if (lang === 'ru') {
      const locale = import('@angular/common/locales/ru');
      registerLocaleData(locale, 'ru');
    } else if (lang === 'sk') {
      const locale = import('@angular/common/locales/sk');
      registerLocaleData(locale, 'sk');
    } else if (lang === 'sl') {
      const locale = import('@angular/common/locales/sl');
      registerLocaleData(locale, 'sl');
    } else if (lang === 'sv') {
      const locale = import('@angular/common/locales/sv');
      registerLocaleData(locale, 'sv');
    } else if (lang === 'tr') {
      const locale = import('@angular/common/locales/tr');
      registerLocaleData(locale, 'tr');
    } else if (lang === 'uk') {
      const locale = import('@angular/common/locales/uk');
      registerLocaleData(locale, 'uk');
    } else if (lang === 'zh') {
      const locale = import('@angular/common/locales/zh');
      registerLocaleData(locale, 'zh');
    }
  }
}
