import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';
import { OpeningHoursCreatorDialogComponent } from '@app/shared/components/opening-hours-creator-dialog/opening-hours-creator-dialog.component';
import { Location } from '@angular/common';
import { onboardingRouteSteps } from '../onboarding.service';
import { DateTime } from 'luxon';
import { InfoAlertComponent } from '@app/shared/components/info-alert/info-alert.component';
import { DeleteAlertComponent } from '@app/shared/components/delete-alert/delete-alert.component';
import { OpeningHoursEditorDialogComponent } from '@app/shared/components/opening-hours-editor-dialog/opening-hours-editor-dialog.component';
import { OpeninghoursService } from '@app/shared/openinghours.service';
import { TranslateService } from '@ngx-translate/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-onboarding-opening-hours',
  templateUrl: './onboarding-opening-hours.component.html',
  styleUrls: ['./onboarding-opening-hours.component.scss'],
})
export class OnboardingOpeningHoursComponent implements OnInit {
  openingHours: IOpeningHours[] = [];
  restaurantId: number = -1;
  isLoading = false;

  displayedColumns: string[] = [
    'weekday',
    'opens',
    'closes',
    'bookingClosesMinutesBefore',
    'buttons',
  ];

  constructor(
    private openingHoursService: OpeninghoursService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: Dialog,
    private location: Location,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private supabase: SupabaseService
  ) {}

  ngOnInit(): void {
    const paramRestaurantId = this.route.snapshot.paramMap.get('restaurant-id');
    this.restaurantId = paramRestaurantId ? +paramRestaurantId : -1;
    if (this.restaurantId > 0) {
      this.getOpeningHours();
    } else {
      this.router.navigate([onboardingRouteSteps.step2]);
    }
  }

  newGeneralOpeningHours() {
    let dialogRef = this.dialog.open(OpeningHoursCreatorDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: { restaurantId: this.restaurantId, areaId: undefined },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.getOpeningHours();
      }
    });
  }

  newAreaOpeningHour(areId: number) {
    let dialogRef = this.dialog.open(OpeningHoursCreatorDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: { restaurantId: this.restaurantId, areaId: areId },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.openingHours.push(result);
      }
    });
  }

  getWeekDay(weekday: number, length: string) {
    if (length === 'short') {
      return DateTime.now().set({ weekday: weekday }).toFormat('ccc', {
        locale: this.i18nService.currentLanguage,
      });
    } else {
      return DateTime.now().set({ weekday: weekday }).toFormat('cccc', {
        locale: this.i18nService.currentLanguage,
      });
    }
  }

  editOpeningHour(openingHourToEdit: IOpeningHours) {
    console.log('opening hour to edit', openingHourToEdit);
    let dialogRef = this.dialog.open(OpeningHoursEditorDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: { openingHoursData: openingHourToEdit },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        const updatedData = result;
        const index = this.openingHours.findIndex(
          (o) => o.id === updatedData.id
        );

        this.openingHours[index] = updatedData;
        this.openingHours = [...this.openingHours];
      }
    });
  }

  deleteOpeningHour(openingHourId: number) {
    let dialogRef = this.dialog.open(DeleteAlertComponent, {
      autoFocus: '__non_existing_element__',
      data: { name: '' },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.openingHours = this.openingHours.filter(
          (o) => o.id !== openingHourId
        );
        this.openingHoursService
          .deleteGeneralOpeningHour(openingHourId)
          .then((r) => this.getOpeningHours());
      }
    });
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step21, this.restaurantId]);
  }

  async next() {
    if (this.openingHours.length > 0) {
      await this.supabase.updateUsersProgressInOnboardingProcess(
        'openingHoursDone'
      );

      this.router.navigate([onboardingRouteSteps.step41, this.restaurantId]);
    } else {
      this.dialog.open(InfoAlertComponent, {
        autoFocus: '__non_existing_element__',
        data: {
          title: this.translateService.instant(
            'openingHours.titleOpeningHoursRequired'
          ),
          description: this.translateService.instant(
            'openingHours.descriptionOpeningHoursRequired'
          ),
        },
      });
    }
  }

  private async getOpeningHours() {
    const allOpeningHours =
      await this.openingHoursService.getOpeningHoursByRestaurant(
        this.restaurantId
      );

    this.openingHours = allOpeningHours.filter((o) => o.areaId === null);
  }
}
