import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { SupabaseService } from '@app/shared/supabase.service';
import { Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { DateTime } from 'luxon';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-organization',
  templateUrl: './onboarding-organization.component.html',
  styleUrls: ['./onboarding-organization.component.scss'],
})
export class OnboardingOrganizationComponent implements OnInit {
  isLoading: boolean = false;
  organizationInfoForm = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    firstname: new FormControl<string>('', Validators.required),
    lastname: new FormControl<string>('', Validators.required),
    phonenumber: new FormControl<string>('', Validators.required),
  });
  currentStep: number = 1;
  totalSteps!: number;
  currentStepSubscription!: Subscription;

  textLabelsPhoneInput = {
    mainLabel: 'Phone number',
    codePlaceholder: 'Country code',
    searchPlaceholderLabel: 'Search',
    noEntriesFoundLabel: 'No countries found',
    nationalNumberLabel: 'Phone number',
    hintLabel: 'Select country and type your phone number',
    invalidNumberError: 'Phone number is not valid',
    requiredError: 'This field is required',
  };

  constructor(
    private router: Router,
    private supabase: SupabaseService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.translateService
      .stream('onboarding.titles.1')
      .subscribe((translatedTitle: string) => {
        this.titleService.setTitle(translatedTitle);
      });

    try {
      const tenantData = await this.supabase.getTenant();
      const currentUser = await this.supabase.getCurrentUser();
      if (currentUser.name === currentUser.email) {
        currentUser.name = '';
      }
      if (tenantData && tenantData.name) {
        this.organizationInfoForm.setValue({
          name: tenantData.name,
          firstname: currentUser.name ? currentUser.name?.split(' ')[0] : '',
          lastname: currentUser.name
            ? currentUser.name.substring(
                currentUser.name?.split(' ')[0].length + 1
              )
            : '',
          phonenumber: currentUser.phone ? currentUser.phone : '',
        });
      }
    } catch (error) {
      console.error('Error when trying to get tenant data', error);
    }

    this.translateService
      .get([
        'general.search.phone-input.mainLabel',
        'general.search.phone-input.codePlaceholder',
        'general.search.phone-input.searchPlaceholderLabel',
        'general.search.phone-input.noEntriesFoundLabel',
        'general.search.phone-input.nationalNumberLabel',
        'general.search.phone-input.hintLabel',
        'general.search.phone-input.invalidNumberError',
        'general.search.phone-input.requiredError',
      ])
      .subscribe((translations) => {
        this.textLabelsPhoneInput = {
          mainLabel: translations['general.search.phone-input.mainLabel'],
          codePlaceholder:
            translations['general.search.phone-input.codePlaceholder'],
          searchPlaceholderLabel:
            translations['general.search.phone-input.searchPlaceholderLabel'],
          noEntriesFoundLabel:
            translations['general.search.phone-input.noEntriesFoundLabel'],
          nationalNumberLabel:
            translations['general.search.phone-input.nationalNumberLabel'],
          hintLabel: translations['general.search.phone-input.hintLabel'],
          invalidNumberError:
            translations['general.search.phone-input.invalidNumberError'],
          requiredError:
            translations['general.search.phone-input.requiredError'],
        };
      });
  }

  async submit() {
    this.isLoading = true;
    if (this.organizationInfoForm.valid) {
      const { name, firstname, lastname, phonenumber } =
        this.organizationInfoForm.value;

      try {
        const tenant = await this.supabase.getTenant();
        const tenantId = tenant?.id;
        await Promise.all([
          this.supabase.setTenantData(name ?? 'NA'),
          this.supabase.updateCurrentUser(
            firstname + ' ' + lastname,
            phonenumber!
          ),
          this.supabase.setTenantTimezone(DateTime.now().zone.name, tenantId),
          this.supabase.setTenantDateFormat(this.guessDateFormat(), tenantId),
          this.supabase.setTenantTimeFormat(this.guessTimeFormat(), tenantId),
          this.supabase.setTenantDefaultLanguage(this.guessDefaultLanguage()),
          this.supabase.updateUsersProgressInOnboardingProcess(
            'restaurantDetailsCompleted'
          ),
        ]);
      } catch (error) {
        console.error('Error when trying to set organization name', error);
      } finally {
        this.isLoading = false;
        this.router.navigate([onboardingRouteSteps.step2]);
      }
    }
  }

  guessDateFormat() {
    // Format a known date (2000-01-02)
    const formattedDate = new Intl.DateTimeFormat(navigator.language).format(
      new Date(2000, 0, 2)
    );

    // Check where the parts of the date appear in the formatted string
    const dayIndex = formattedDate.indexOf('2');
    const monthIndex = formattedDate.indexOf('1');
    const yearIndex = formattedDate.indexOf('2000');

    // Based on the indices, guess the date format
    if (dayIndex < monthIndex && monthIndex < yearIndex) {
      return 'DD.MM.YYYY';
    } else if (monthIndex < dayIndex && dayIndex < yearIndex) {
      return 'MM/DD/YYYY';
    } else if (yearIndex < monthIndex && monthIndex < dayIndex) {
      return 'YYYY.MM.DD';
    } else {
      return 'DD.MM.YYYY';
    }
  }

  guessTimeFormat() {
    // Format a known time (13:00)
    const formattedTime = new Intl.DateTimeFormat(navigator.language, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(2000, 0, 2, 13, 0));

    // Check if the formatted time contains 'AM' or 'PM'
    if (formattedTime.includes('AM')) {
      return 'HH12:MI AM';
    } else if (formattedTime.includes('PM')) {
      return 'HH12:MI PM';
    } else {
      return 'HH24:MI';
    }
  }

  private guessDefaultLanguage() {
    return navigator.languages[0].substring(0, 2);
  }
}
