import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';
import { DateTime, Interval } from 'luxon';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';
import { weekdayNumberToDay } from 'src/utils/weekday-number-to-day';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';
import { TranslationService } from '@app/shared/translation.service';
import { OpeninghoursService } from '@app/shared/openinghours.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

interface OpeningHoursEditorDialogData {
  openingHoursData: IOpeningHours;
}

@Component({
  selector: 'app-opening-hours-editor-dialog',
  templateUrl: './opening-hours-editor-dialog.component.html',
  styleUrls: ['./opening-hours-editor-dialog.component.scss'],
})
export class OpeningHoursEditorDialogComponent implements OnInit {
  openingHourForm = new FormGroup({
    weekday: new FormControl(-1),
    opens: new FormControl(''),
    closes: new FormControl(''),
    bookingClosesMinutesBefore: new FormControl(0),
    name: new FormControl<ITranslationVM[]>([
      {
        title: this.translateService.instant('openingHours.name.title'),
        description: this.translateService.instant(
          'openingHours.name.description'
        ),
      },
    ]),
    activeFromDate: new FormControl<string>(
      DateTime.now().toFormat('yyyy-MM-dd'),
      { nonNullable: true }
    ),
    activeToDate: new FormControl<string>(
      DateTime.now().plus({ year: 1 }).toFormat('yyyy-MM-dd'),
      { nonNullable: true }
    ),
    specialOpeningHoursThatShouldOverrideOthers: new FormControl<boolean>(
      false,
      { nonNullable: true }
    ),
    isOpen: new FormControl<boolean>(true, { nonNullable: true }),
  });
  openingHours?: IOpeningHours;
  weekday?: string;

  constructor(
    private translationService: TranslationService,
    private openingHoursService: OpeninghoursService,
    public dialogRef: DialogRef<OpeningHoursEditorDialogData>,
    @Inject(DIALOG_DATA) public data: OpeningHoursEditorDialogData,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {
    this.openingHours = data.openingHoursData;
  }

  ngOnInit(): void {
    if (this.openingHours) {
      this.openingHourForm.get('weekday')?.setValue(this.openingHours.weekday);
      this.openingHourForm.get('opens')?.setValue(
        DateTime.now()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .plus({ minute: this.openingHours.opens })
          .toFormat('HH:mm') // gjør dette siden input type="time" må ha 24 timersklokke
      );
      this.openingHourForm.get('closes')?.setValue(
        DateTime.now()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .plus({ minute: this.openingHours.closes })
          .toFormat('HH:mm') // gjør dette siden input type="time" må ha 24 timersklokke
      );
      this.openingHourForm
        .get('bookingClosesMinutesBefore')
        ?.setValue(+this.openingHours.bookingClosesMinutesBefore);

      this.openingHourForm
        .get('activeFromDate')
        ?.setValue(this.openingHours.activeFromDate.toFormat('yyyy-MM-dd'));
      this.openingHourForm
        .get('activeToDate')
        ?.setValue(this.openingHours.activeToDate.toFormat('yyyy-MM-dd'));

      this.openingHourForm.get('isOpen')?.setValue(this.openingHours.isOpen);

      this.openingHourForm
        .get('specialOpeningHoursThatShouldOverrideOthers')
        ?.setValue(
          this.openingHours.specialOpeningHoursThatShouldOverrideOthers
        );

      this.weekday = weekdayNumberToDay(this.openingHours.weekday);
      console.log(
        'weekdayNumberToDay(this.openingHours.weekday)',
        this.openingHours.weekday
      );

      if (this.openingHours && this.openingHours.textId) {
        this.translationService
          .getTranslations(this.openingHours.textId)
          .then((res) => {
            console.debug('res', res);
            this.openingHourForm.get('name')?.setValue(res);
          });
      } else {
        this.openingHourForm.get('name')?.setValue([
          {
            title: this.translateService.instant('openingHours.name.title'),
            description: this.translateService.instant(
              'openingHours.name.description'
            ),
          },
        ]);
      }
    }
  }

  async onSubmit() {
    let values = this.openingHourForm.getRawValue();

    if (this.openingHours === undefined) return;
    if (values.opens === null || values.closes === null) {
      throw new Error('Opening or closing hours should never be null');
    }

    if (values.bookingClosesMinutesBefore === null) {
      throw new Error('bookingClosesMinutesBefore should never be null');
    }

    const openingDateTime = DateTime.fromFormat(values.opens, 'HH:mm').set({
      second: 0,
      millisecond: 0,
    });
    const closesDateTime = DateTime.fromFormat(values.closes, 'HH:mm').set({
      second: 0,
      millisecond: 0,
    });
    const midnightDateTime = DateTime.now().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const openingHoursAsMinutesFromMidnight = Interval.fromDateTimes(
      midnightDateTime,
      openingDateTime
    ).length('minutes');
    const closingHoursAsMinutesFromMidnight = Interval.fromDateTimes(
      midnightDateTime,
      closesDateTime
    ).length('minutes');
    console.log(
      "this.openingHourForm.get('name')?.value",
      this.openingHourForm.get('name')?.value
    );

    if (values.weekday && values.weekday > 0 && values.weekday < 8) {
      const overlappingOpeningHours =
        await this.openingHoursService.checkIfOpeningHoursOverlap(
          values.weekday,
          DateTime.fromISO(values.activeFromDate + ''),
          DateTime.fromISO(values.activeToDate + ''),
          values.specialOpeningHoursThatShouldOverrideOthers,
          values.isOpen,
          this.openingHours.areaId,
          this.openingHours.restaurantId,
          this.openingHours.id,
          openingHoursAsMinutesFromMidnight,
          closingHoursAsMinutesFromMidnight
        );

      if (overlappingOpeningHours.length > 0) {
        const overlappingHoursMessages = overlappingOpeningHours
          .map(
            (oh) =>
              `${
                oh.name
                  ? oh.name
                  : DateTime.now()
                      .set({ weekday: oh.weekday })
                      .toFormat('cccc', {
                        locale: this.translateService.currentLang,
                      })
              } - ${oh.activeFromDate.toFormat(
                'dd.MM.yyyy'
              )} - ${oh.activeToDate.toFormat('dd.MM.yyyy')}`
          )
          .join('\n');

        const message = this.translateService.instant(
          'openingHours.overlapping.error'
        );

        this.toastrService.error(overlappingHoursMessages, message);
        return;
      }

      const textId =
        await this.translationService.upsertTranslationsAndGetTextId(
          this.openingHourForm.get('name')?.value as ITranslationVM[]
        );

      const response = await this.openingHoursService.upsertOpeningHours(
        this.openingHours.id,
        this.openingHours.restaurantId,
        values.weekday,
        openingHoursAsMinutesFromMidnight,
        closingHoursAsMinutesFromMidnight,
        values.bookingClosesMinutesBefore,
        values.activeFromDate + '',
        values.activeToDate + '',
        values.specialOpeningHoursThatShouldOverrideOthers,
        values.isOpen,
        this.openingHours.areaId,
        textId
      );

      this.dialogRef.close(response);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
