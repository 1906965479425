<app-stepper-page-template
  [title]="'openingHours.onboarding.title'"
  [description]="'openingHours.onboarding.stepDescription'"
  [progressTitle]="'openingHours.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="3"
>
  <p class="mt-3" translate>openingHours.onboarding.description</p>
  <p class="mt-3" translate>openingHours.onboarding.description2</p>

  <button
    class="btn btn-primary min-w-36 mt-6"
    (click)="newGeneralOpeningHours()"
  >
    <span translate>openingHours.newOpeningHours</span>
  </button>

  <div class="opening-hours-table" *ngIf="openingHours.length > 0">
    <table class="table table-auto w-full mt-4">
      <thead>
        <tr>
          <th translate>general.weekday</th>
          <th class="hidden sm:table-cell" translate>general.opens</th>
          <th class="hidden sm:table-cell" translate>general.closes</th>
          <th class="sm:hidden" translate>general.openinghours</th>
          <th translate>general.bookingCutOff</th>
          <th>From date</th>
          <th>To date</th>
          <th>Overrides other opening hours</th>
          <th>Open</th>
          <th class="edit-section"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of openingHours">
          <td class="sm:hidden">
            <div>{{ getWeekDay(element.weekday, "short").toString() }}</div>
            <div class="block name">{{ element.name }}</div>
          </td>
          <td class="hidden sm:table-cell">
            <div>{{ getWeekDay(element.weekday, "long").toString() }}</div>
            <span class="block name">{{ element.name }}</span>
          </td>
          <td class="hidden sm:table-cell">{{ element.opensString }}</td>
          <td class="hidden sm:table-cell">{{ element.closesString }}</td>
          <td class="sm:hidden">
            {{ element.opensString }} - {{ element.closesString }}
          </td>
          <td>
            <ng-container *ngIf="element.bookingClosesMinutesBefore > 0">
              {{ element.bookingClosesMinutesBefore }}
              <span class="hidden sm:table-cell" translate
                >general.general.bookingCutOff</span
              >
            </ng-container>
            <ng-container
              *ngIf="
                element.bookingClosesMinutesBefore <= 0 ||
                !element.bookingClosesMinutesBefore
              "
            >
              <span translate>openingHours.nocutoffadded</span>
            </ng-container>
          </td>
          <td>{{ element.activeFromDate | localizedDate : "medium" }}</td>
          <td>{{ element.activeToDate | localizedDate : "medium" }}</td>
          <td *ngIf="!element.specialOpeningHoursThatShouldOverrideOthers">
            &nbsp;
          </td>
          <td *ngIf="element.specialOpeningHoursThatShouldOverrideOthers">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="var(--warning-color)"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
          </td>
          <td>
            <svg
              *ngIf="element.isOpen"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="var(--primary-color)"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <svg
              *ngIf="!element.isOpen"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="var(--warning-color)"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </td>
          <td>
            <div class="flex flex-row">
              <button
                class="btn btn-outline"
                (click)="editOpeningHour(element)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
              <button
                class="btn btn-outline btn-error"
                (click)="deleteOpeningHour(element.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-6 flex flex-row flex-wrap w-full justify-between">
    <button class="btn btn-outline min-w-36" (click)="back()" translate>
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
